
import { apiClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { SignUpToWebinarRequest } from "zaehlerfreunde-central/misc_service_pb";

const webinarSeenCookieKey = "webinar-seen-new";

@Component
export default class WebinarSignUpDialog extends Vue {
  @Prop()
  value: boolean;

  showDialog = false;
  showSuccessSnackbar = false;

  signUpCall: RemoteCall<UserError> = initialized;

  name = "";
  company = "";
  email = "";

  @Watch("value")
  onValueChanged() {
    if (this.value) {
      this.showDialog = true;
    }
  }

  @Watch("showDialog")
  onShowDialogChanged() {
    this.$emit("input", this.showDialog);
  }

  get isInfoComplete(): boolean {
    return !!this.name && !!this.company && !!this.email;
  }

  async signUp(): Promise<void> {
    try {
      this.signUpCall = pending;

      await apiClient.signUpToWebinar(
        new SignUpToWebinarRequest().setName(this.name).setCompany(this.company).setEmail(this.email),
        {}
      );

      this.signUpCall = success(void 0);
      this.showDialog = false;
      this.showSuccessSnackbar = true;
      this.$cookies.set(webinarSeenCookieKey, true);
    } catch (error) {
      this.signUpCall = failure(userErrorFrom(error));
    }
  }

  onNotInterestedClicked(): void {
    this.showDialog = false;
    this.$cookies.set(webinarSeenCookieKey, true);
  }

  mounted(): void {
    if (!this.$cookies.get(webinarSeenCookieKey)) {
      this.showDialog = true;
    }
  }
}
