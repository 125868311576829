import { RouteConfig } from "vue-router";
import {
  Account,
  ConnectDevice,
  Dashboard,
  Devices,
  Platform,
  Registration,
  SmartMeSelectDevice,
  PowerfoxCallbackPage,
  SpaceSettings,
  TariffSwitch,
  Tips,
  Simulator,
  MiniPVSimulator,
  MiniPVSimulationResults,
  ImportBluemeteringData,
  UpdateManualReadingsPage,
  TibberSelectHomesPage,
  Reporting,
} from "@/pages/platform";
import { isAdminGuard, isAllowedAutomationsGuard, isLoggedInGuard, notLoggedInGuard, visitorTypeGuard } from "./guards";
import {
  Homepage,
  B2CLandingPage,
  AboutUs,
  Blog,
  Press,
  WhiteLabelLandingPage,
  Article,
  Pricing,
  VisitorTypePrompt,
  B2BLandingPage,
  MeterToTenantFeatures,
  MeteringOperatorFeatures,
  MunicipalUtilitiesFeatures,
  BluemeteringSuccessStory,
  EnergielandwerkerSuccessStory,
  RohrerSuccessStory,
  E3SuccessStory,
} from "@/pages/homepage";
import { Legal, PrivacyPolicy, Terms, ShopTerms } from "@/pages/legal";
import { ErrorPage, Faq, Imprint, NotFound, Shop, Product, AcceptInvite } from "@/pages/shared";
import {
  AdminPlatform,
  AdminSpacesOverview,
  AdminDevicesOverview,
  AdminUsersOverview,
  ImportData,
  ExportData,
  MetergridImport,
  AdminInvitesOverview,
  AdminSettings,
  AdminAppearance,
  Forwarding,
  ForwardingRule,
  UserProfile,
  SpaceProfile,
  DeviceInfo,
  ApiOverview,
  AdminsOverview,
  EditInviteEmail,
} from "@/pages/admin-platform";
import InvoicesOverview from "@/pages/platform/invoices/InvoicesOverview.vue";
import { VisitorType } from "@/observables/visitor-type-observables";
import RouterView from "@/components/router-view/RouterView.vue";
import { i18n } from "@/locales/i18n";
import ViessmannAvailableDevicesPage from "@/pages/platform/connect-device/viessmann/ViessmannAvailableDevicesPage.vue";
import Automations from "@/pages/platform/automations/Automations.vue";
import AutomationDetails from "@/pages/platform/automations/AutomationDetails.vue";
import GoEOCPPInstruction from "@/pages/instructions/GoeOcppInstruction.vue";
import Instructions from "@/pages/instructions/Instructions.vue";
import OcppInstructions from "@/pages/instructions/OcppInstructions.vue";
import KebaOcppInstruction from "@/pages/instructions/KebaOcppInstruction.vue";
import WebastoOcppInstruction from "@/pages/instructions/WebastoOcppInstruction.vue";
import EvboxOcppInstruction from "@/pages/instructions/EvboxOcppInstruction.vue";
import JuiceOcppInstruction from "@/pages/instructions/JuiceOcppInstruction.vue";
import MyenergiOcppInstruction from "@/pages/instructions/MyenergiOcppInstruction.vue";
import HeidelbergOcppInstruction from "@/pages/instructions/HeidelbergOcppInstruction.vue";
import AblOcppInstruction from "@/pages/instructions/AblOcppInstruction.vue";
import WallboxOcppInstruction from "@/pages/instructions/WallboxOcppInstruction.vue";
import AbbOcppInstruction from "@/pages/instructions/AbbOcppInstruction.vue";
import ChargeAmpsOcppInstruction from "@/pages/instructions/ChargeAmpsOcppInstruction.vue";
import BitShakeInstructions from "@/pages/instructions/BitShake/BitShakeInstructions.vue";
import DemoPage from "@/pages/demo/DemoPage.vue";
import AdminLandlordToTenantOverview from "@/pages/admin-platform/landlord-to-tenant/AdminLandlordToTenantOverview.vue";
import SingleProject from "@/pages/admin-platform/landlord-to-tenant/SingleProject.vue";
import ReportContent from "@/pages/platform/reporting/ReportContent.vue";

export const paths = {
  home: "/",
  homepage: {
    visitorTypePrompt: "/",
    privateCustomersPath: "/privatkunden",
    privateCustomers: {
      b2cLandingPage: "/privatkunden",
      shop: "/privatkunden/shop",
      pricing: "/privatkunden/preise",
    },

    commercialCustomers: "/geschaeftskunden",
    whiteLabelPath: "/white-label",
    whiteLabel: {
      landingPage: "/white-label",
      utilityProviders: "/white-label/energieversorger",
      municipalUtilities: "/white-label/stadtwerke",
      propertyManagement: "/white-label/wohnungswirtschaft",
      meterToTenantProjects: "/white-label/mieterstromprojekte",
      meteringOperators: "/white-label/messstellenbetreiber",
      successStories: {
        bluemetering: "/white-label/success-stories/bluemetering",
        rohrer: "/white-label/success-stories/rohrer",
        e3: "/white-label/success-stories/e3",
        energielandwerker: "/white-label/success-stories/energielandwerker",
      },
    },
    aboutUs: "/about",
    blog: "/blog",
    press: "/presse",
    imprint: "/impressum",
  },
  demoPath: "/demo",
  platformPath: "/platform",
  platform: {
    dashboard: "/platform/dashboard",
    tips: "/platform/tipps",
    tariffSwitch: "/platform/tarifwechsel",
    devices: "/platform/geraete",
    automations: "/platform/automationen",
    spaceSettings: "/platform/objektverwaltung",
    invoices: "/platform/nvoices",
    accountPath: "/platform/account",
    account: {
      general: "/platform/account/allgemein",
      subscriptions: "/platform/account/abo",
    },
    faq: "/platform/faq",
    registration: "/platform/registration",
    connectDevice: "/platform/geraet-verbinden",
    connectSmartMeDevice: "/platform/smart-me-verbindung",
    connectViessmannAvailableDevices: "/platform/viessmann-verbindung",
    shop: "/platform/shop",
    simulator: "/platform/simulator",
    miniPvSimulator: "/platform/mini-pv-simulation",
    simulation: "/platform/simulation",
    connectTibberPulse: "/platform/geraet-verbinden/tibber",
    powerfoxConnection: "/platform/powerfox-verbindung",
    blueMeteringImport: "/platform/bluemetering-import",
    updateManualReadings: "/platform/update-manual-readings",
    reporting: "/platform/reporting",
  },
  adminPath: "/admin",
  admin: {
    spaces: `/admin/spaces`,
    devices: "/admin/devices",
    users: "/admin/users",
    import: "/admin/import",
    export: "/admin/export",
    metergrid: "/admin/import/metergrid",
    invitesPath: "/admin/invites",
    invites: {
      editEmail: "/admin/invites/edit-email",
    },
    connectDevice: "/admin/connect-device",
    settings: "/admin/settings",
    appearance: "/admin/appearance",
    forwarding: "/admin/forwarding",
    api: "/admin/api",
    admins: "/admin/admins",
    mieterstrom: "/admin/mieterstrom",
  },
  legalPath: "/legal",
  legal: {
    imprint: "/legal/impressum",
    dataPrivacy: "/legal/datenschutz",
    terms: "/legal/agb",
    shopTerms: "/legal/shop-agb",
    faq: "/legal/faq",
  },
  instructionsPath: "/instructions",
  instructions: {
    ocpp: "/instructions/ocpp",
    goe: "/instructions/ocpp/goe",
    keba: "/instructions/ocpp/keba",
    webasto: "/instructions/ocpp/webasto",
    evbox: "/instructions/ocpp/evbox",
    abl: "/instructions/ocpp/abl",
    heidelberg: "/instructions/ocpp/heidelberg",
    juice: "/instructions/ocpp/juice",
    myenergi: "/instructions/ocpp/myenergi",
    wallbox: "/instructions/ocpp/wallbox",
    abb: "/instructions/ocpp/abb",
    chargeamps: "/instructions/ocpp/chargeamps",
    bitshake: "/instructions/bitshake",
  },

  faq: "/faq",
  terms: "/agb",
  dataPrivacy: "/datenschutz",
  tariffSwitch: "/tarifwechsel",
  error: "/error",
  acceptInvite: "/accept-invite",
};

const routes: RouteConfig[] = [
  {
    path: paths.home,
    component: Homepage,
    beforeEnter: notLoggedInGuard,
    children: [
      {
        path: paths.homepage.visitorTypePrompt,
        component: VisitorTypePrompt,
        beforeEnter: visitorTypeGuard(null),
        meta: {
          gradientBackground: true,
        },
      },
      {
        path: paths.homepage.privateCustomersPath,
        component: RouterView,
        beforeEnter: visitorTypeGuard(VisitorType.PrivateCustomer),
        children: [
          {
            path: paths.homepage.privateCustomers.b2cLandingPage,
            component: B2CLandingPage,
          },
          {
            path: paths.homepage.privateCustomers.shop,
            component: Shop,
            meta: {
              title: i18n.t("shopPage.title"),
              subtitle: i18n.t("shopPage.subtitle"),
              titleImg: "devices.png",
            },
          },
          {
            path: paths.homepage.privateCustomers.pricing,
            component: Pricing,
            meta: {
              title: i18n.t("pricesPage.title"),
              subtitle: i18n.t("pricesPage.subtitle"),
              titleImg: "multiple-devices-mobile.png",
            },
          },
        ],
      },
      {
        path: paths.homepage.commercialCustomers,
        component: B2BLandingPage,
        beforeEnter: visitorTypeGuard(VisitorType.CommercialCustomer),
      },
      {
        path: paths.homepage.whiteLabelPath,
        component: RouterView,
        beforeEnter: visitorTypeGuard(VisitorType.WhiteLabelCustomer),
        children: [
          {
            path: paths.homepage.whiteLabel.landingPage,
            component: WhiteLabelLandingPage,
            meta: {
              addSpaceForBanner: false,
            },
          },
          {
            path: paths.homepage.whiteLabel.utilityProviders,
            component: MunicipalUtilitiesFeatures,
            meta: {
              title: `${i18n.t("for")} ${i18n.t("landingPage.whiteLabel.customerGroups.utilityProvider.name")}`,
              subtitle: i18n.t("whiteLabelFeaturesPage.utilityProvidersSubtitle"),
            },
          },
          {
            path: paths.homepage.whiteLabel.municipalUtilities,
            component: MunicipalUtilitiesFeatures,
            meta: {
              title: `${i18n.t("for")} ${i18n.t("landingPage.whiteLabel.customerGroups.municipalUtilities.name")}`,
              subtitle: i18n.t("whiteLabelFeaturesPage.municipalUtilitiesSubtitle"),
            },
          },
          {
            path: paths.homepage.whiteLabel.meteringOperators,
            component: MeteringOperatorFeatures,
            meta: {
              title: `${i18n.t("for")} ${i18n.t("landingPage.whiteLabel.customerGroups.meteringOperator.name")}`,
              subtitle: i18n.t("whiteLabelFeaturesPage.meteringOperatorsSubtitle"),
            },
          },
          {
            path: paths.homepage.whiteLabel.meterToTenantProjects,
            component: MeterToTenantFeatures,
            meta: {
              title: `${i18n.t("for")} ${i18n.t("landingPage.whiteLabel.customerGroups.meterToTenant.name")}`,
              subtitle: i18n.t("whiteLabelFeaturesPage.meterToTenantSubtitle"),
            },
          },
        ],
      },
      {
        path: paths.homepage.aboutUs,
        component: AboutUs,
        meta: {
          title: i18n.t("aboutUsPage.title"),
          subtitle: i18n.t("aboutUsPage.subtitle"),
        },
      },
      {
        path: paths.homepage.blog,
        component: Blog,
        meta: {
          title: i18n.t("blogPage.title"),
          subtitle: i18n.t("blogPage.subtitle"),
        },
      },
      {
        path: `${paths.homepage.blog}/:title`,
        component: Article,
      },
      {
        path: paths.homepage.press,
        component: Press,
        meta: {
          title: i18n.t("pressPage.title"),
        },
      },
      {
        path: paths.homepage.imprint,
        component: Imprint,
        meta: {
          title: i18n.t("imprint"),
        },
      },
      {
        path: paths.homepage.whiteLabel.successStories.bluemetering,
        component: BluemeteringSuccessStory,
        meta: {
          title: "BlueMetering",
          subtitle: "Deshalb setzt der wettbewerbliche Messstellenbetreiber BlueMetering auf Zählerfreunde",
        },
      },
      {
        path: paths.homepage.whiteLabel.successStories.rohrer,
        component: RohrerSuccessStory,
        meta: {
          title: "Rohrer",
          subtitle: "Rohrer setzt auf Zählerfreunde: Intelligentes Energiemanagement für nachhaltige Immobilien",
        },
      },
      {
        path: paths.homepage.whiteLabel.successStories.energielandwerker,
        component: EnergielandwerkerSuccessStory,
        meta: {
          title: "Energielandwerker",
          subtitle: "Wie Zählerfreunde für Betreiber von Erneuerbaren Energieanlagen die Stromproduktion visualisiert",
        },
      },
      {
        path: paths.homepage.whiteLabel.successStories.e3,
        component: E3SuccessStory,
        meta: {
          title: "e3 Energieanlagen",
          subtitle: "Energieanlagenunternehmen e3 setzt auf Zählerfreunde",
        },
      },
    ],
  },
  {
    path: paths.demoPath,
    component: DemoPage,
  },

  {
    path: paths.platformPath,
    component: Platform,
    beforeEnter: isLoggedInGuard,
    children: [
      {
        path: paths.platform.dashboard,
        component: Dashboard,
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: paths.platform.tips,
        component: Tips,
        meta: {
          title: "Tipps",
        },
      },
      {
        path: paths.platform.tariffSwitch,
        component: TariffSwitch,
        meta: {
          title: "Tarifwechsel",
        },
      },
      {
        path: paths.platform.devices,
        component: Devices,
        meta: {
          title: "Geräte",
        },
      },
      {
        path: paths.platform.automations,
        component: Automations,
        beforeEnter: isAllowedAutomationsGuard,
        meta: {
          title: "Smarte Steuerung",
        },
      },
      {
        path: `${paths.platform.automations}/:automationId`,
        component: AutomationDetails,
      },
      {
        path: paths.platform.spaceSettings,
        component: SpaceSettings,
        meta: {
          title: "Objekt",
        },
      },
      {
        path: paths.platform.invoices,
        component: InvoicesOverview,
        meta: {
          title: "Rechnungen",
        },
      },
      {
        path: paths.platform.accountPath,
        redirect: paths.platform.account.general,
      },
      {
        path: `${paths.platform.accountPath}/:tab`,
        component: Account,
        meta: {
          title: "Mein Account",
        },
      },
      {
        path: paths.platform.faq,
        component: Faq,
        meta: {
          title: "FAQ",
        },
      },
      {
        path: paths.platform.registration,
        components: {
          withoutNav: Registration,
        },
        meta: {
          hideNavigation: true,
        },
      },
      {
        path: paths.platform.connectTibberPulse,
        components: {
          withoutNav: TibberSelectHomesPage,
        },
        meta: {
          hideNavigation: true,
        },
      },
      {
        path: paths.platform.connectDevice,
        components: {
          withoutNav: ConnectDevice,
        },
        meta: {
          hideNavigation: true,
        },
      },
      {
        path: `${paths.platform.connectDevice}/:provider`,
        components: {
          withoutNav: ConnectDevice,
        },
        meta: {
          hideNavigation: true,
        },
      },
      {
        path: paths.platform.connectSmartMeDevice,
        components: {
          withoutNav: SmartMeSelectDevice,
        },
        meta: {
          hideNavigation: true,
        },
      },
      {
        path: paths.platform.shop,
        component: Shop,
        meta: {
          title: "Shop",
        },
      },
      {
        path: `${paths.platform.shop}/:productId`,
        component: Product,
        meta: {
          title: "Shop",
        },
      },
      {
        path: paths.platform.simulator,
        component: Simulator,
        meta: {
          title: "Simulator",
        },
      },
      {
        path: paths.platform.miniPvSimulator,
        components: {
          withoutNav: MiniPVSimulator,
        },
        meta: {
          hideNavigation: true,
        },
      },
      {
        path: `${paths.platform.simulation}/:id`,
        component: MiniPVSimulationResults,
        meta: {
          title: "Simulation",
        },
      },
      {
        path: paths.platform.powerfoxConnection,
        components: {
          withoutNav: PowerfoxCallbackPage,
        },
        meta: {
          hideNavigation: true,
        },
      },
      {
        path: paths.platform.blueMeteringImport,
        components: {
          withoutNav: ImportBluemeteringData,
        },
        meta: {
          hideNavigation: true,
        },
      },
      {
        path: `${paths.platform.updateManualReadings}/:deviceId`,
        components: {
          withoutNav: UpdateManualReadingsPage,
        },
        meta: {
          hideNavigation: true,
        },
      },
      {
        path: paths.platform.connectViessmannAvailableDevices,
        components: {
          withoutNav: ViessmannAvailableDevicesPage,
        },
        meta: {
          hideNavigation: true,
        },
      },
      {
        path: paths.platform.reporting,
        component: Reporting,
        meta: {
          title: "Reporting",
        },
      },
      {
        path: `${paths.platform.reporting}/:reportId`,
        component: ReportContent,
      },
    ],
  },
  {
    path: paths.adminPath,
    component: AdminPlatform,
    beforeEnter: isAdminGuard,
    children: [
      {
        path: paths.admin.spaces,
        name: "adminSpacesOverview",
        component: AdminSpacesOverview,
        props: (route) => ({
          page: route.query.page,
          search: route.query.search,
        }),
        meta: {
          title: "Objekte",
        },
      },
      {
        path: `${paths.admin.users}/:userId`,
        name: "adminUserOverview",
        component: UserProfile,
        props: (route) => ({
          page: route.query.page,
          search: route.query.search,
        }),
      },
      {
        path: `${paths.admin.spaces}/:spaceId`,
        name: "adminSpacesOverview",
        component: SpaceProfile,
      },
      {
        path: paths.admin.devices,
        name: "adminDevicesOverview",
        component: AdminDevicesOverview,
        props: (route) => ({
          page: route.query.page,
          search: route.query.search,
        }),
        meta: {
          title: "Geräte",
        },
      },
      {
        path: `${paths.admin.devices}/:deviceId`,
        name: "adminDeviceOverview",
        component: DeviceInfo,
      },
      {
        path: paths.admin.users,
        component: AdminUsersOverview,
        meta: {
          title: "Nutzer",
        },
      },
      {
        path: paths.admin.import,
        component: ImportData,
        meta: {
          title: "Datenimport",
        },
      },
      {
        path: paths.admin.export,
        component: ExportData,
        meta: {
          title: "Datenexport",
        },
      },
      {
        path: paths.admin.metergrid,
        components: {
          withoutNav: MetergridImport,
        },
        meta: {
          hideNavigation: true,
        },
      },
      {
        path: paths.admin.invitesPath,
        component: AdminInvitesOverview,
        meta: {
          title: "Einladungen",
        },
      },
      {
        path: paths.admin.invites.editEmail,
        component: EditInviteEmail,
        meta: {
          title: "Email Anpassen",
        },
      },
      {
        path: paths.admin.connectDevice,
        components: {
          withoutNav: ConnectDevice,
        },
        meta: {
          hideNavigation: true,
        },
      },
      {
        path: paths.admin.forwarding,
        component: Forwarding,
        meta: {
          title: "Datenweiterleitung",
        },
      },
      {
        path: `${paths.admin.forwarding}/:ruleId`,
        component: ForwardingRule,
      },
      {
        path: paths.admin.settings,
        component: AdminSettings,
        meta: {
          title: "Einstellungen",
        },
      },
      {
        path: paths.admin.appearance,
        component: AdminAppearance,
        meta: {
          title: "Darstellung",
        },
      },
      {
        path: paths.admin.api,
        component: ApiOverview,
        meta: {
          title: "API",
        },
      },
      {
        path: paths.admin.admins,
        component: AdminsOverview,
        meta: {
          title: "Admins",
        },
      },
      {
        path: paths.admin.mieterstrom,
        component: AdminLandlordToTenantOverview,
        props: (route) => ({
          page: route.query.page,
          search: route.query.search,
        }),
        meta: {
          title: "Mieterstrom",
        },
      },
      {
        path: `${paths.admin.mieterstrom}/:projectId`,
        name: "adminProjectOverview",
        component: SingleProject,
      },
    ],
  },
  {
    path: paths.legalPath,
    component: Legal,
    redirect: paths.legal.imprint,
    children: [
      {
        path: paths.legal.imprint,
        component: Imprint,
        meta: {
          title: "Impressum",
        },
      },
      {
        path: paths.legal.dataPrivacy,
        component: PrivacyPolicy,
        meta: {
          title: "Datenschutz",
        },
      },
      {
        path: paths.legal.terms,
        component: Terms,
        meta: {
          title: "AGB",
        },
      },
      {
        path: paths.legal.shopTerms,
        component: ShopTerms,
        meta: {
          title: "Shop AGB",
        },
      },
      {
        path: paths.legal.faq,
        component: Faq,
        meta: {
          title: "FAQ",
        },
      },
    ],
  },

  {
    path: paths.instructionsPath,
    component: Instructions,
    meta: {
      title: "Anleitungen",
    },
    redirect: paths.instructions.ocpp,
    children: [
      {
        path: paths.instructions.ocpp,
        component: OcppInstructions,
      },
      {
        path: paths.instructions.goe,
        component: GoEOCPPInstruction,
      },
      {
        path: paths.instructions.keba,
        component: KebaOcppInstruction,
      },
      {
        path: paths.instructions.webasto,
        component: WebastoOcppInstruction,
      },
      {
        path: paths.instructions.evbox,
        component: EvboxOcppInstruction,
      },
      {
        path: paths.instructions.juice,
        component: JuiceOcppInstruction,
      },
      {
        path: paths.instructions.myenergi,
        component: MyenergiOcppInstruction,
      },
      {
        path: paths.instructions.heidelberg,
        component: HeidelbergOcppInstruction,
      },
      {
        path: paths.instructions.abl,
        component: AblOcppInstruction,
      },
      {
        path: paths.instructions.wallbox,
        component: WallboxOcppInstruction,
      },
      {
        path: paths.instructions.abb,
        component: AbbOcppInstruction,
      },
      {
        path: paths.instructions.chargeamps,
        component: ChargeAmpsOcppInstruction,
      },
    ],
  },
  {
    path: paths.instructions.bitshake,
    component: BitShakeInstructions,
    meta: {
      title: "Anleitungen",
    },
  },
  {
    path: "/index.html",
    redirect: paths.home,
  },
  {
    path: paths.faq,
    redirect: paths.legal.faq,
  },
  {
    path: paths.terms,
    redirect: paths.legal.terms,
  },
  {
    path: paths.dataPrivacy,
    redirect: paths.legal.dataPrivacy,
  },
  {
    path: paths.tariffSwitch,
    redirect: paths.platform.tariffSwitch,
  },
  {
    path: paths.error,
    component: ErrorPage,
  },
  {
    path: `${paths.acceptInvite}/:inviteId/:partnerId?`,
    component: AcceptInvite,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

export default routes;
