
import { Component, Vue, Watch } from "vue-property-decorator";
import PersonalInfos from "./PersonalInfos.vue";
import LoginInfos from "./LoginInfos.vue";
import { accountModule } from "@/store/modules/account";
import partner, { partnerModule } from "@/store/modules/partner";
import { RemoteCall, RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import Subscription from "./Subscription.vue";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/user_pb";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import { paths } from "@/router/routes";

@Component({
  components: {
    PersonalInfos,
    LoginInfos,
    Subscription,
  },
})
export default class Account extends Vue {
  @accountModule.State deleteAccountCall: RemoteCall<UserError>;
  @partnerModule.State partner: RemoteData<UserError, Partner>;

  SubscriptionPlan = SubscriptionPlan;
  tab: number = 0;

  @Watch("tab")
  onTabChanged(): void {
    if (this.tab === 0 && this.$route.params["tab"] !== "allgemein") {
      this.$router.push(paths.platform.account.general);
    } else if (this.tab === 1 && this.$route.params["tab"] !== "abo") {
      this.$router.push(paths.platform.account.subscriptions);
    }
  }

  metaInfo(): { title: string } {
    return {
      title: "Mein Account",
    };
  }

  mounted(): void {
    partner.getPartner();

    const tab = this.$route.params["tab"];
    if (tab === "abo") {
      this.tab = 1;
    } else {
      this.tab = 0;
    }
  }
}
