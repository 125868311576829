
import { Component, Vue, Watch } from "vue-property-decorator";
import EnergyConsumerInfos from "./EnergyConsumerInfos.vue";
import SpaceInfos from "./SpaceInfos.vue";
import TariffInfos from "./tariff/TariffInfos.vue";
import UserInfos from "./UserInfos.vue";
import spaces, { spacesModule } from "@/store/modules/spaces";
import tariff from "@/store/modules/tariff";
import tips from "@/store/modules/tips";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { featuresModules } from "@/store/modules/features";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import SpaceTariffs from "./tariff/SpaceTariffs.vue";
import ReferenceValueSettings from "./ReferenceValueSettings.vue";

@Component({
  components: {
    EnergyConsumerInfos,
    SpaceInfos,
    SpaceTariffs,
    TariffInfos,
    UserInfos,
    ReferenceValueSettings,
  },
})
export default class SpacesOverview extends Vue {
  @spacesModule.State selectedSpace: Space | null;
  @featuresModules.Getter enabledFeatures: Set<Feature>;

  Feature = Feature;
  zfProtoDeviceMedium = Device.Medium;

  metaInfo(): { title: string } {
    return {
      title: "Objekt",
    };
  }

  beforeMount(): void {
    this.loadData();
  }

  @Watch("selectedSpace")
  onSelectedSpaceChanged(): void {
    this.loadData();
  }

  private loadData(): void {
    if (this.selectedSpace) {
      tips.getEnergyConsumers();
      tariff.getTariffSearchInfo();
      spaces.getChildSpaces();
    }
  }

  hasAtLeastOneTariffsFeature(): boolean {
    return (
      this.enabledFeatures.has(Feature.ENERGY_TARIFF) ||
      this.enabledFeatures.has(Feature.WATER_TARIFF) ||
      this.enabledFeatures.has(Feature.GAS_TARIFF)
    );
  }
}
