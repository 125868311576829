
import { Component, Vue, Watch } from "vue-property-decorator";
import { RemoteData, failure, initialized, pending, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import consumption from "@/store/modules/consumption";
import { spacesModule } from "@/store/modules/spaces";
import tips from "@/store/modules/tips";
import tariff from "@/store/modules/tariff";
import devices, { devicesModule } from "@/store/modules/devices";
import partner from "@/store/modules/partner";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { DashboardLayout } from "zaehlerfreunde-proto-types/dashboard_layout_pb";
import { uiServiceClient } from "@/config/service-clients";
import { GetDashboardLayoutRequest } from "zaehlerfreunde-central/ui_service_pb";
import EditDashboardLayout, { LayoutChangedEvent } from "./EditDashboardLayout.vue";
import { Device } from "zaehlerfreunde-proto-types/device_pb";
import DashboardItemComponent from "./DashboardItemComponent.vue";
import DashboardTipPresenter from "./DashboardTipPresenter.vue";
import CreateFirstSpaceReminder from "./CreateFirstSpaceReminder.vue";
import WelcomeMessage from "./WelcomeMessage.vue";
import ConnectDeviceReminder from "./ConnectDeviceReminder.vue";
import UpdateManualReadings from "./update-manual-readings/UpdateManualReadings.vue";

@Component({
  components: {
    EditDashboardLayout,
    DashboardItemComponent,
    DashboardTipPresenter,
    CreateFirstSpaceReminder,
    WelcomeMessage,
    ConnectDeviceReminder,
    UpdateManualReadings,
  },
})
export default class Dashboard extends Vue {
  @spacesModule.State selectedSpace: Space | null;
  @spacesModule.State spaces: RemoteData<UserError, Space[]>;
  @devicesModule.State devices: RemoteData<UserError, Device[]>;

  dashboardLayout: RemoteData<UserError, DashboardLayout> = initialized;
  editing: boolean = false;
  scrollY: number = 0;

  @Watch("selectedSpace")
  onSelectedSpaceChanged(): void {
    this.loadLayout();
    this.loadData();
  }

  get hasSuccessfullyConnectedDevice(): boolean {
    return this.devices.list.some((d) => d.getStatus() === Device.Status.CONNECTED);
  }

  private loadData(): void {
    partner.getPartner();

    tips.getTips();

    if (this.selectedSpace) {
      tariff.getCurrentEnergyTariff();
      tariff.getTariffSearchResults();
      tariff.getPendingTariffSwitch();
      tariff.getTariffSearchInfo();
      consumption.getCurrentMonthConsumptionOfSelectedSpace();
      tips.getEnergyConsumers();
      devices.getDevices();
    }
  }

  async mounted() {
    this.loadLayout();

    this.loadData();
  }

  layoutChanged(event: LayoutChangedEvent) {
    this.scrollY = event.scrollY;
    this.dashboardLayout = success(event.newLayout);
  }

  async loadLayout(): Promise<void> {
    this.dashboardLayout = pending;
    const request = new GetDashboardLayoutRequest();

    const spaceId = this.selectedSpace?.getId();
    if (spaceId) {
      request.setSpaceId(spaceId);
    }

    try {
      const response = await uiServiceClient.getDashboardLayout(request, {});

      const items = response.getLayout();
      if (items) {
        this.dashboardLayout = success(items);
      }
    } catch (error) {
      this.dashboardLayout = failure(userErrorFrom(error));
    }
  }
}
