
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { AddUpdateLandlordToTenantEnergyTariffRequest } from "zaehlerfreunde-central/landlord_to_tenant_service_pb";
import { landlordToTenantServiceClient } from "@/config/service-clients";
import { LandlordToTenantEnergyTariff } from "zaehlerfreunde-proto-types/landlord_to_tenant_pb";

@Component
export default class AddUpdateLandlordToTenantEnergyTariff extends Vue {
  @Prop({ default: null }) projectId: string | null;
  @Prop({ default: null }) tariffId: string | null = null;
  @Prop({ default: null }) solarPrice: number | null = null;
  @Prop({ default: null }) residualPrice: number | null = null;
  @Prop({ default: null }) basePrice: number | null = null;
  addTariffCall: RemoteCall<UserError> = initialized;

  requiredRule: (value: string) => boolean | string = (value: string) => !!value || "Pflichtfeld";

  get infoComplete(): boolean {
    return this.solarPrice != null && this.residualPrice != null && this.basePrice != null;
  }

  async addUpdateEnergyTariff(): Promise<void> {
    this.addTariffCall = pending;
    const request = new AddUpdateLandlordToTenantEnergyTariffRequest();

    if (this.solarPrice && this.residualPrice && this.basePrice && this.projectId) {
      const tariff = new LandlordToTenantEnergyTariff();
      if (this.tariffId) {
        tariff.setId(this.tariffId);
      }
      tariff.setSolarPrice(this.solarPrice);
      tariff.setResidualPrice(this.residualPrice);
      tariff.setBasePricePerMonth(this.basePrice);
      request.setTariff(tariff);
      request.setProjectId(this.projectId);
      try {
        await landlordToTenantServiceClient.addUpdateLandlordToTenantEnergyTariff(request, {});
        this.addTariffCall = success(void 0);
        this.$emit("input", false);
      } catch (error) {
        this.addTariffCall = failure(userErrorFrom(error));
      }
    }
  }
}
