
import { landlordToTenantServiceClient } from "@/config/service-clients";
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import {
  GetLandlordToTenantProjectRequest,
  GetLandlordToTenantEnergyTariffRequest,
} from "zaehlerfreunde-central/landlord_to_tenant_service_pb";
import SingleProjectDevices from "./SingleProjectDevices.vue";
import DeleteLandlordToTenantProjectDialog from "./Dialogs/DeleteLandlordToTenantProjectDialog.vue";
import {
  LandlordToTenantEnergyTariff,
  LandlordToTenantProject,
} from "zaehlerfreunde-proto-types/landlord_to_tenant_pb";
import { paths } from "@/router/routes";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";
import AddUpdateLandlordToTenantEnergyTariff from "./AddUpdateLandlordToTenantEnergyTariff.vue";
@Component({
  components: { SingleProjectDevices, DeleteLandlordToTenantProjectDialog, AddUpdateLandlordToTenantEnergyTariff },
})
export default class SingleProject extends Vue {
  singleProject: RemoteData<UserError, LandlordToTenantProject> = initialized;
  projectTariff: RemoteData<UserError, LandlordToTenantEnergyTariff | undefined> = initialized;
  showDeleteProjectDialog: boolean = false;
  showAddUpdateTariffDialog: boolean = false;
  selectedTab: number = 1;
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  deleteProjectCall: RemoteCall<UserError> = initialized;
  projectId: string | null = null;

  @Watch("selectedChildPartner")
  onChildPartnerChange(): void {
    this.$router.push(paths.admin.devices);
  }

  changeTab(number: number) {
    this.selectedTab = number;
  }

  async mounted(): Promise<void> {
    await this.loadProject();
    await this.loadTariff();
  }
  async loadProject(): Promise<void> {
    this.showDeleteProjectDialog = false;
    this.projectId = this.$route.params.projectId;
    const request = new GetLandlordToTenantProjectRequest();
    request.setProjectId(this.projectId);
    if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

    try {
      const response = await landlordToTenantServiceClient.getLandlordToTenantProject(request, {});
      const project = response.getProject();
      if (project) {
        this.singleProject = success(project);
      }
    } catch (error) {
      this.singleProject = failure(userErrorFrom(error));
    }
  }

  async loadTariff(): Promise<void> {
    this.showAddUpdateTariffDialog = false;
    this.projectTariff = pending;
    this.projectId = this.$route.params.projectId;
    const request = new GetLandlordToTenantEnergyTariffRequest();
    request.setProjectId(this.projectId);
    if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

    try {
      const response = await landlordToTenantServiceClient.getLandlordToTenantEnergyTariff(request, {});
      const tariff = response.getTariff();

      this.projectTariff = success(tariff);
    } catch (error) {
      this.projectTariff = failure(userErrorFrom(error));
    }
  }

  goBackToProjects(): void {
    this.$router.go(-1);
  }
}
