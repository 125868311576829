
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { DeleteLandlordToTenantMeterRequest } from "zaehlerfreunde-central/landlord_to_tenant_service_pb";
import { landlordToTenantServiceClient } from "@/config/service-clients";
@Component
export default class DeleteLandlordToTenantMeterDialog extends Vue {
  @Prop({ default: null }) meterId: string | null;
  @Prop({ default: null }) deviceId: string | null;
  @Prop({ default: null }) deviceName: string | null;
  deleteMeterCall: RemoteCall<UserError> = initialized;

  async onDeleteClicked(): Promise<void> {
    if (this.meterId) {
      try {
        this.deleteMeterCall = pending;
        const request = new DeleteLandlordToTenantMeterRequest();

        request.setMeterId(this.meterId);
        if (this.deviceId) {
          request.setDeviceId(this.deviceId);
        }
        await landlordToTenantServiceClient.deleteLandlordToTenantMeter(request, {});

        this.deleteMeterCall = success(void 0);

        this.$emit("meter-deleted", false);
      } catch (error) {
        this.deleteMeterCall = failure(userErrorFrom(error));
      }
    }
  }
}
