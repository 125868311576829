
import { Vue, Component, Watch } from "vue-property-decorator";
import { UserError, userErrorFrom } from "@/types/user-error";
import { pending, failure, success, initialized, RemoteData, RemoteCall } from "@/store/utils/remote-data";
import { deviceServiceClient, partnerServiceClient } from "@/config/service-clients";
import {
  GetCustomizationConfigRequest,
  UpdateCustomizationConfigRequest,
} from "zaehlerfreunde-central/partner_service_pb";
import { GetDeviceProvidersRequest } from "zaehlerfreunde-central/device_service_pb";
import { emailRules } from "@/utils/rules";
import { Partner, PartnerCustomizationConfig } from "zaehlerfreunde-proto-types/partners_pb";
import { partnerModule } from "@/store/modules/partner";
import { StatusCode } from "grpc-web";
import FeatureNotIncludedDialog from "@/components/subscription/FeatureNotIncludedDialog.vue";

interface SettingGroup {
  title: string;
  subtitle: string;
  items: SettingItem[];
}

interface SettingItem {
  title: string;
  subtitle: string;
  key: string;
}

@Component({ components: { FeatureNotIncludedDialog } })
export default class AdminSettings extends Vue {
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  updateConfig: RemoteCall<UserError> = initialized;
  customConfig: RemoteData<UserError, PartnerCustomizationConfig | undefined> = initialized;
  deviceProviders: RemoteData<UserError, { name: string; value: number }[]> = initialized;
  enabledDeviceProviders: { name: string; value: number }[] = [];
  savedEmail: string | null = null;
  supportEmailSaved: boolean = false;
  emailRules = emailRules;
  featureNotIncludedError: boolean = false;

  settingGroups: SettingGroup[] = [
    {
      title: "Sicherheit",
      subtitle: "Wer kann sich als Nutzer anmelden?",
      items: [
        {
          title: "Einladung Erforderlich",
          subtitle: "Neue Nutzer können sich nur per Einladung anmelden.",
          key: "registrationInviteOnly",
        },
      ],
    },
    {
      title: "Dashboard",
      subtitle: "Welche Dashboard Widgets sollen angezeigt werden?",
      items: [
        {
          title: "Übersicht",
          subtitle: "Zeige Monatsübersicht Widgets.",
          key: "hideOverview",
        },
        {
          title: "Aktueller Gerätestatus",
          subtitle: "Zeige den zuletzt übermittelten Wert aller Geräte.",
          key: "hideDeviceStatus",
        },
        {
          title: "Support Chatbox",
          subtitle: "Zeige die Chatbox für Support Fragen unten rechts an.",
          key: "hideChatbox",
        },
        {
          title: "Day-Ahead Preise",
          subtitle: "Day-Ahead Spot Preise immer anzeigen, unabhängig vom Tarif des Nutzers",
          key: "alwaysShowMarketPrices",
        },
        {
          title: "Marktprämie für Anlagen",
          subtitle: "Nur relevant für Erzeugeranlagen. Hochrechnung des Marktwerts/prämie der Anlage.",
          key: "hideMarketPremium",
        },
      ],
    },
    {
      title: "Funktionalitäten",
      subtitle: "Funktionsumfang der Plattform.",
      items: [
        {
          title: "Tarifwechsel",
          subtitle: "Sollen Nutzer den Tarif wechseln dürfen?",
          key: "hideTariffSwitch",
        },
        {
          title: "Simulator",
          subtitle: "Erlaube Nutzern Simulationen auszuführen.",
          key: "hideSimulations",
        },
        {
          title: "Rechnungen",
          subtitle: "Zeige den Nutzern Rechnungen.",
          key: "hideInvoices",
        },
        {
          title: "Tipps",
          subtitle: "Zeige den Nutzern Empfehlungen.",
          key: "hideTips",
        },
        {
          title: "Shop",
          subtitle: "Zeige den Shop.",
          key: "hideShop",
        },
        {
          title: "Objektvergleich",
          subtitle: "Objektvergleich z.B. von Wohnungen im gleichen Gebäude.",
          key: "hideSpaceComparison",
        },
        {
          title: "Automationen",
          subtitle: "Erlaube die smarte Steuerung von Geräten",
          key: "hideAutomations",
        },
        {
          title: "Reporting",
          subtitle: "Erlaube automatisiertes Reporting",
          key: "hideReporting",
        },
      ],
    },
    {
      title: "Tarife",
      subtitle: "Welche Tarife sollen Nutzer hinzufügen können?",
      items: [
        {
          title: "Stromtarif",
          subtitle: "Erlaube Nutzern Stromtarife hinzuzufügen.",
          key: "hideEnergyTariff",
        },
        {
          title: "Wassertarif",
          subtitle: "Erlaube Nutzern Wassertarif hinzuzufügen.",
          key: "hideWaterTariff",
        },
        {
          title: "Gastarif",
          subtitle: "Erlaube Nutzern Gastarif hinzuzufügen.",
          key: "hideGasTariff",
        },
      ],
    },
  ];

  customConfigData = {
    hideTariffSwitch: false,
    hideShop: false,
    hideSimulations: false,
    hideInvoices: false,
    hideTips: false,
    hideDeviceStatus: false,
    hideOverview: false,
    hideEnergyTariff: false,
    hideGasTariff: false,
    hideWaterTariff: false,
    registrationInviteOnly: false,
    hideSpaceComparison: false,
    supportEmail: "",
    hideAutomations: false,
    hideChatbox: false,
    alwaysShowMarketPrices: false,
    hideMarketPremium: false,
    hideReporting: false,
  };

  loading = {
    hideTariffSwitch: false,
    hideShop: false,
    hideSimulations: false,
    hideInvoices: false,
    hideTips: false,
    hideDeviceStatus: false,
    hideOverview: false,
    hideEnergyTariff: false,
    hideGasTariff: false,
    hideWaterTariff: false,
    registrationInviteOnly: false,
    disabledDeviceProviders: false,
    hideSpaceComparison: false,
    supportEmail: false,
    hideAutomations: false,
    hideChatbox: false,
    alwaysShowMarketPrices: false,
    hideMarketPremium: false,
    hideReporting: false,
  };

  @Watch("selectedChildPartner")
  onChildPartnerChange(): void {
    if (this.selectedChildPartner.data) {
      this.getProviderList().then(() => this.getCustomConfig());
    }
  }

  beforeMount(): void {
    this.getProviderList().then(() => this.getCustomConfig());
  }

  async getCustomConfig() {
    try {
      const request = new GetCustomizationConfigRequest();

      if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

      const response = await partnerServiceClient.getCustomizationConfig(request, {});
      this.customConfig = success(response.getConfig());

      if (this.customConfig.data != undefined) {
        this.customConfigData.hideTariffSwitch = !this.customConfig.data.getHideTariffSwitch();
        this.customConfigData.hideShop = !this.customConfig.data.getHideShop();
        this.customConfigData.hideSimulations = !this.customConfig.data.getHideSimulations();
        this.customConfigData.hideInvoices = !this.customConfig.data.getHideInvoices();
        this.customConfigData.hideTips = !this.customConfig.data.getHideTips();
        this.customConfigData.hideDeviceStatus = !this.customConfig.data.getHideDeviceStatus();
        this.customConfigData.hideOverview = !this.customConfig.data.getHideOverview();
        this.customConfigData.hideEnergyTariff = !this.customConfig.data.getHideEnergyTariff();
        this.customConfigData.hideGasTariff = !this.customConfig.data.getHideGasTariff();
        this.customConfigData.hideWaterTariff = !this.customConfig.data.getHideWaterTariff();
        this.customConfigData.registrationInviteOnly = this.customConfig.data.getRegistrationInviteOnly();
        this.customConfigData.hideSpaceComparison = !this.customConfig.data.getHideSpaceComparison();
        this.customConfigData.supportEmail = this.customConfig.data.getSupportEmail();
        this.customConfigData.hideChatbox = !this.customConfig.data.getHideChatbox();
        this.customConfigData.alwaysShowMarketPrices = this.customConfig.data.getAlwaysShowMarketPrices();
        this.savedEmail = this.customConfig.data.getSupportEmail();
        this.supportEmailSaved = false;
        this.customConfigData.hideAutomations = !this.customConfig.data.getHideAutomations();
        this.customConfigData.hideMarketPremium = !this.customConfig.data.getHideMarketPremium();
        this.customConfigData.hideReporting = !this.customConfig.data.getHideReporting();

        if (this.customConfig.data != undefined && this.deviceProviders.data != undefined) {
          this.enabledDeviceProviders = this.customConfig.data
            .getEnabledDeviceProvidersList()
            .map(
              (enabledProvider) =>
                this.deviceProviders.list.find((p) => p.value == enabledProvider) ?? { name: "", value: -1 }
            )
            .filter((p) => p.value >= 0);
        }
      }
    } catch (error) {
      this.customConfig = failure(userErrorFrom(error));
    }
  }

  async updateCustomConfig(key = "") {
    this.updateConfig = pending;
    this.loading[key] = true;
    try {
      const request = new UpdateCustomizationConfigRequest();

      if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

      const config = new PartnerCustomizationConfig();
      config.setHideTariffSwitch(!this.customConfigData.hideTariffSwitch);
      config.setHideShop(!this.customConfigData.hideShop);
      config.setHideSimulations(!this.customConfigData.hideSimulations);
      config.setHideInvoices(!this.customConfigData.hideInvoices);
      config.setHideTips(!this.customConfigData.hideTips);
      config.setHideDeviceStatus(!this.customConfigData.hideDeviceStatus);
      config.setHideOverview(!this.customConfigData.hideOverview);
      config.setHideEnergyTariff(!this.customConfigData.hideEnergyTariff);
      config.setHideGasTariff(!this.customConfigData.hideGasTariff);
      config.setHideWaterTariff(!this.customConfigData.hideWaterTariff);
      config.setRegistrationInviteOnly(this.customConfigData.registrationInviteOnly);
      config.setHideSpaceComparison(!this.customConfigData.hideSpaceComparison);
      config.setEnabledDeviceProvidersList(this.enabledDeviceProviders.map((provider) => provider!.value));
      config.setSupportEmail(this.isValidEmail ? this.supportEmail : this.customConfig.data?.getSupportEmail() ?? "");
      config.setHideAutomations(!this.customConfigData.hideAutomations);
      config.setHideChatbox(!this.customConfigData.hideChatbox);
      config.setAlwaysShowMarketPrices(this.customConfigData.alwaysShowMarketPrices);
      config.setHideMarketPremium(!this.customConfigData.hideMarketPremium);
      config.setHideReporting(!this.customConfigData.hideReporting);

      request.setConfig(config);
      const response = await partnerServiceClient.updateCustomizationConfig(request, {});
      this.updateConfig = success(void response);
      this.savedEmail = this.supportEmail;
      this.supportEmailSaved = true;
    } catch (error: any) {
      if (error.code == StatusCode.PERMISSION_DENIED) {
        this.featureNotIncludedError = true;
      }
      this.getCustomConfig();
      this.updateConfig = failure(userErrorFrom(error));
    }

    this.loading[key] = false;
  }

  @Watch("supportEmail")
  async emailChanged(): Promise<void> {
    this.supportEmailSaved = false;
  }

  get supportEmail(): string {
    return this.customConfigData.supportEmail ?? "support@zaehlerfreunde.com";
  }
  get isValidEmail(): boolean {
    return !emailRules.some((rule) => typeof rule(this.supportEmail) === "string") ?? false;
  }
  get supportEmailTouched(): boolean {
    return this.supportEmail != this.savedEmail ?? false;
  }

  async getProviderList() {
    this.deviceProviders = pending;
    try {
      const request = new GetDeviceProvidersRequest();
      request.setIncludeDisabledProviders(true);

      if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

      const response = await deviceServiceClient.getDeviceProviders(request, {});
      this.deviceProviders = success(
        response.getDeviceProvidersList().map((provider) => {
          return {
            name: provider.getName(),
            value: provider.getValue(),
          };
        })
      );
    } catch (error) {
      this.deviceProviders = failure(userErrorFrom(error));
    }
  }
}
