
import { Component, Prop } from "vue-property-decorator";
import { SubscriptionPlan } from "zaehlerfreunde-proto-types/user_pb";
import TariffWatchdogExplanation from "@/components/subscription/TariffWatchdogExplanation.vue";
import { RemoteDataVue } from "@/store/utils/remote-data";
import { subscriptionModule } from "@/store/modules/subscription";

interface PlanDetails {
  value: SubscriptionPlan;
  name: string;
  subtitle: string;
  icon: Icon;
  pricePerMonth: number;
  isActive: boolean;
  mostPopular: boolean;
  features: Feature[];
  button: ActivateButton;
  details?: string;
}

enum Icon {
  gift,
  rocket,
}

interface Feature {
  available: boolean;
  description: string;
  moreInfoCallback?: () => void;
}

export interface ActivateButton {
  text: string;
  show: boolean;
  disabled: boolean;
  event?: string;
  errorColor?: boolean;
}

@Component({
  components: {
    TariffWatchdogExplanation,
  },
})
export default class SubscriptionPlans extends RemoteDataVue {
  @subscriptionModule.Getter currentPlan: SubscriptionPlan;
  @Prop({ default: false }) allowActivation: boolean;
  @Prop() freePlanButton: ActivateButton;
  @Prop() proPlanButton: ActivateButton;
  @Prop() b2bPlanButton: ActivateButton;
  @Prop({ default: false }) isB2BPartner: boolean;

  showTariffWatchdogExplanation: boolean = false;
  Icon = Icon;

  get plans(): PlanDetails[] {
    if (this.isB2BPartner) {
      return [
        {
          value: SubscriptionPlan.B2B,
          name: this.$t("plans.b2b.name").toString(),
          subtitle: this.$t("plans.b2b.subtitle").toString(),
          icon: Icon.rocket,
          pricePerMonth: 49.99,
          mostPopular: true,
          details: this.$t("plans.b2b.details").toString(),
          isActive: this.allowActivation && this.currentPlan === SubscriptionPlan.B2B,
          features: [
            { available: true, description: this.$t("plans.b2b.features.0").toString() },
            { available: true, description: this.$t("plans.b2b.features.1").toString() },
            { available: true, description: this.$t("plans.b2b.features.2").toString() },
            { available: true, description: this.$t("plans.b2b.features.3").toString() },
            { available: true, description: this.$t("plans.b2b.features.4").toString() },
            { available: true, description: this.$t("plans.b2b.features.5").toString() },
            { available: true, description: this.$t("plans.b2b.features.6").toString() },
            { available: true, description: this.$t("plans.b2b.features.7").toString() },
            { available: true, description: this.$t("plans.b2b.features.8").toString() },
          ],
          button: this.b2bPlanButton,
        },
      ];
    } else
      return [
        {
          value: SubscriptionPlan.FREE,
          name: this.$t("plans.free.name").toString(),
          subtitle: this.$t("plans.free.subtitle").toString(),
          icon: Icon.gift,
          mostPopular: false,
          pricePerMonth: 0,
          isActive: this.allowActivation && this.currentPlan === SubscriptionPlan.FREE,
          features: [
            { available: true, description: this.$t("plans.free.features.0").toString() },
            { available: true, description: this.$t("plans.free.features.1").toString() },
            { available: true, description: this.$t("plans.free.features.2").toString() },
            { available: true, description: this.$t("plans.free.features.3").toString() },
            { available: false, description: this.$t("plans.free.features.4").toString() },
            { available: false, description: this.$t("plans.free.features.5").toString() },
            { available: false, description: this.$t("plans.free.features.6").toString() },
          ],
          button: this.freePlanButton,
        },
        {
          value: SubscriptionPlan.PRO,
          name: this.$t("plans.pro.name").toString(),
          subtitle: this.$t("plans.pro.subtitle").toString(),
          icon: Icon.rocket,
          pricePerMonth: 3.49,
          mostPopular: true,
          details: this.$t("plans.pro.details").toString(),
          isActive: this.allowActivation && this.currentPlan === SubscriptionPlan.PRO,
          features: [
            { available: true, description: this.$t("plans.pro.features.0").toString() },
            {
              available: true,
              description: this.$t("plans.pro.features.1").toString(),
            },
            { available: true, description: this.$t("plans.pro.features.2").toString() },
            { available: true, description: this.$t("plans.pro.features.3").toString() },
            { available: true, description: this.$t("plans.pro.features.4").toString() },
            {
              available: true,
              description: this.$t("plans.pro.features.5").toString(),
              moreInfoCallback: () => {
                this.showTariffWatchdogExplanation = true;
              },
            },
            { available: true, description: this.$t("plans.pro.features.6").toString() },
            {
              available: true,
              description: this.$t("plans.pro.features.7").toString(),
            },
            {
              available: true,
              description: this.$t("plans.pro.features.8").toString(),
            },
          ],
          button: this.proPlanButton,
        },
      ];
  }

  onActivateButtonClicked(button: ActivateButton): void {
    if (button.event) {
      this.$emit(button.event);
    }
  }
}
