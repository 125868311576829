
import { failure, initialized, pending, RemoteCall, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Prop } from "vue-property-decorator";
import { DeleteLandlordToTenantSpaceRequest } from "zaehlerfreunde-central/landlord_to_tenant_service_pb";
import { landlordToTenantServiceClient } from "@/config/service-clients";
@Component
export default class DeleteLandlordToTenantSpaceDialog extends Vue {
  @Prop({ default: null }) id: string | null;
  @Prop({ default: null }) spaceName: string | null;
  deleteSpaceCall: RemoteCall<UserError> = initialized;

  async onDeleteClicked(): Promise<void> {
    if (this.id) {
      try {
        this.deleteSpaceCall = pending;
        const request = new DeleteLandlordToTenantSpaceRequest();

        request.setId(this.id);

        await landlordToTenantServiceClient.deleteLandlordToTenantSpace(request, {});

        this.deleteSpaceCall = success(void 0);

        this.$emit("space-deleted", false);
      } catch (error) {
        this.deleteSpaceCall = failure(userErrorFrom(error));
      }
    }
  }
}
