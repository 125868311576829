
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ChangeCategory from "./ChangeCategory.vue";
import ChangeSpaceName from "./ChangeSpaceName.vue";
import ChangeIngestPrice from "./ChangeIngestPrice.vue";
import { spacesModule } from "@/store/modules/spaces";
import { Space } from "zaehlerfreunde-proto-types/space_pb";
import { paths } from "@/router/routes";
import ChangeSpaceAddress from "./ChangeSpaceAddress.vue";
import DeleteSpaceDialog from "../../../components/delete-space/DeleteSpaceDialog.vue";
import { featuresModules } from "@/store/modules/features";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import ChangeChildSpaces from "./ChangeChildSpaces.vue";
import { RemoteData } from "@/store/utils/remote-data";
import { UserError } from "@/types/user-error";
import { spaceServiceClient } from "@/config/service-clients";
import { IsDirectionAvailableRequest } from "zaehlerfreunde-central/space_service_pb";
import { Direction } from "zaehlerfreunde-proto-types/device_reading_pb";

@Component({
  components: {
    ChangeSpaceName,
    ChangeIngestPrice,
    ChangeCategory,
    ChangeSpaceAddress,
    DeleteSpaceDialog,
    ChangeChildSpaces,
  },
})
export default class SpaceInfos extends Vue {
  @spacesModule.State childSpaces: RemoteData<UserError, Space[]>;

  @spacesModule.State selectedSpace: Space | null;
  @featuresModules.Getter enabledFeatures: Set<Feature>;

  isProductionAvailable = false;

  showChangeSpaceNameDialog: boolean = false;
  showChangeCategoryDialog: boolean = false;
  showDeleteSpaceDialog: boolean = false;
  showChangeIngestPriceDialog: boolean = false;
  showChangeAddressDialog: boolean = false;
  showChangeChildSpacesDialog: boolean = false;
  paths = paths;
  answerUnit(answerTitle: string): string {
    if (answerTitle == "Wohnfläche" || answerTitle == "Größe") {
      return " qm";
    }
    if (answerTitle == "Durchschnittsverbrauch pro Jahr") {
      return " kWh";
    }
    if (answerTitle == "CO2 pro kWh") {
      return " g";
    }
    if (answerTitle == "Einspeisevergütung") {
      return " Cent/kWh";
    }
    return "";
  }
  get showEnergyTariff(): boolean {
    return this.enabledFeatures.has(Feature.ENERGY_TARIFF);
  }

  get generalInfos(): { label: string; value: string; onEditClicked?: () => void; explanation?: string }[] {
    return [
      ...(this.selectedSpace
        ? [
            {
              label: "Name",
              value: this.selectedSpace.getName(),
              onEditClicked: () => (this.showChangeSpaceNameDialog = true),
            },
            {
              label: "Typ",
              value: this.selectedSpace.getCategoryName(),
              onEditClicked: () => (this.showChangeCategoryDialog = true),
            },
            ...(this.selectedSpace.hasAddress()
              ? [
                  {
                    label: "Adresse",
                    value: `${this.selectedSpace.getAddress()?.getAddressLineOne()}, ${this.selectedSpace
                      .getAddress()
                      ?.getAddressLineTwo()} ${this.selectedSpace.getAddress()?.getPostcode()} ${this.selectedSpace
                      .getAddress()
                      ?.getCity()}, ${this.selectedSpace.getAddress()?.getCountry()?.getCountryName()}`,
                    onEditClicked: () => (this.showChangeAddressDialog = true),
                  },
                ]
              : []),
          ]
        : []),
      ...(this.showEnergyTariff && this.selectedSpace && this.isProductionAvailable
        ? [
            {
              label: "Einspeisevergütung",
              value: `${this.selectedSpace.getIngestPrice().toFixed(2)} Cent/kWh`,
              onEditClicked: () => (this.showChangeIngestPriceDialog = true),
              explanation:
                "Die Einspeisevergütung ist die festgelegte Vergütung für erzeugtem Strom, der ins Netz eingespeist wird.",
            },
          ]
        : []),
      ...[
        {
          label: "Untergeordnetes Objekt",
          value: this.childSpaces.list.length
            ? this.childSpaces.list.map((space) => space.getName()).join(", ")
            : "Keine Untergeordnete Objekte",
          onEditClicked: () => (this.showChangeChildSpacesDialog = true),
        },
      ],
    ];
  }

  @Watch("selectedSpace")
  async checkIfProductionIsAvailable(): Promise<void> {
    const space = this.selectedSpace;

    if (space) {
      const request = new IsDirectionAvailableRequest();
      request.setDirection(Direction.OUT);
      request.setSpaceId(space.getId());
      const response = await spaceServiceClient.isDirectionAvailable(request, {});

      this.isProductionAvailable = response.getIsDirectionAvailable();
    }
  }

  mounted(): void {
    this.checkIfProductionIsAvailable();
  }
}
