
import { Vue, Component, Watch } from "vue-property-decorator";
import AddAutomationDialog from "./AddAutomationDialog.vue";
import DispatchedActionsTable from "./DispatchedActionsTable.vue";
import DeleteAutomationDialog from "./DeleteAutomationDialog.vue";
import TriggerAutomationDialog from "./TriggerAutomationDialog.vue";
import { UserError, userErrorFrom } from "@/types/user-error";
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { spacesModule } from "@/store/modules/spaces";
import { GetAutomationDescriptionsRequest } from "zaehlerfreunde-central/ui_service_pb";
import { uiServiceClient } from "@/config/service-clients";
import { paths } from "@/router/routes";
import { featuresModules } from "@/store/modules/features";
import { Feature } from "zaehlerfreunde-proto-types/features_pb";
import { AutomationDescription } from "zaehlerfreunde-proto-types/automations_pb";

@Component({
  components: {
    AddAutomationDialog,
    DeleteAutomationDialog,
    DispatchedActionsTable,
    TriggerAutomationDialog,
  },
})
export default class Automations extends Vue {
  @spacesModule.Getter selectedSpaceId: string;
  @featuresModules.Getter enabledFeatures: Set<Feature>;

  automations: RemoteData<UserError, AutomationDescription[]> = initialized;

  showAddAutomationDialog: boolean = false;

  @Watch("selectedSpaceId")
  onSelectedSpaceChanged(): void {
    this.loadData();
  }

  @Watch("enabledFeatures")
  onEnabledFeaturesChanged() {
    if (!this.enabledFeatures.has(Feature.AUTOMATIONS)) {
      this.$router.replace(paths.platform.dashboard);
    }
  }

  async loadData(): Promise<void> {
    this.automations = pending;
    const request = new GetAutomationDescriptionsRequest();
    request.setSpaceId(this.selectedSpaceId);

    try {
      const response = await uiServiceClient.getAutomationDescriptions(request, null);
      this.automations = success(response.getAutomationsList());
    } catch (error) {
      this.automations = failure(userErrorFrom(error));
    }
  }

  handleCardClick(automation: AutomationDescription): void {
    this.$router.push({
      path: `${paths.platform.automations}/${automation.getAutomationId()}`,
    });
  }

  mounted(): void {
    this.loadData();
  }

  onAddAutomationClicked(): void {
    this.showAddAutomationDialog = true;
  }
}
