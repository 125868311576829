
import { failure, initialized, pending, RemoteData, success } from "@/store/utils/remote-data";
import { UserError, userErrorFrom } from "@/types/user-error";
import { Vue, Component, Watch } from "vue-property-decorator";
import { SearchParams } from "@/components/devices-table/DevicesTable.vue";
import ProjectsTable from "@/components/landlord-tenants-project-table/ProjectsTable.vue";
import { GetLandlordToTenantProjectsRequest } from "zaehlerfreunde-central/landlord_to_tenant_service_pb";
import CreateLandlordToTenantProjectDialog from "./Dialogs/CreateLandlordToTenantProjectDialog.vue";
import { LandlordToTenantProject } from "zaehlerfreunde-proto-types/landlord_to_tenant_pb";
import { landlordToTenantServiceClient } from "@/config/service-clients";
import { partnerModule } from "@/store/modules/partner";
import { Partner } from "zaehlerfreunde-proto-types/partners_pb";

@Component({
  components: { ProjectsTable, CreateLandlordToTenantProjectDialog },
})
export default class AdminLandlordToTenantOverview extends Vue {
  partnerProjects: RemoteData<UserError, LandlordToTenantProject[]> = initialized;
  projectPages: RemoteData<UserError, number> = initialized;
  @partnerModule.State selectedChildPartner: RemoteData<UserError, Partner | undefined>;

  showInviteDialog: boolean = false;
  showCreateProjectDialog: boolean = false;
  itemsPerPage = 10;
  page = 1;
  search: string = "";

  @Watch("selectedChildPartner")
  onChildPartnerChange(): void {
    if (this.selectedChildPartner.data) {
      this.loadProjects();
    }
  }

  headerControls = [
    {
      icon: "mdi-plus-circle",
      event: "create-new-project",
      color: "primary",
    },
  ];

  sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  beforeMount(): void {
    const search = (this.$route.query.search as string) ?? "";
    const page = typeof this.$route.query.page === "string" ? parseInt(this.$route.query.page, 10) : 1;
    if (search == "" && page == 1) {
      this.loadProjects();
    }
  }

  async searchParamsInput(params: SearchParams): Promise<void> {
    this.page = params.page;
    this.search = params.search;
    this.loadProjects();
  }

  async loadProjects(): Promise<void> {
    this.showCreateProjectDialog = false;
    this.partnerProjects = pending;
    const request = new GetLandlordToTenantProjectsRequest();
    request.setPageSize(this.itemsPerPage);
    request.setPage(this.page - 1);

    if (this.search != "") {
      request.setSearchFilter(this.search);
    }

    if (this.selectedChildPartner.data) request.setPartnerId(this.selectedChildPartner.data.getId());

    try {
      const response = await landlordToTenantServiceClient.getLandlordToTenantProjects(request, {});
      this.partnerProjects = success(response.getProjectsList());
      this.projectPages = success(response.getTotalNumPages());
    } catch (error) {
      this.partnerProjects = failure(userErrorFrom(error));
      this.projectPages = failure(userErrorFrom(error));
    }
  }
}
